:root {
  --primary: #7955a2;
  --primaryBG: #F6F0FE;
  --primaryText: #6200EE;
  --primaryDark: #543B71;
  --secondary: #019592;
  --yellow: #f7d633;
  --black: #000000FF;
  --white: #fff;
  --background: #FBFBFB;
  --gray-10: #e3e3e3;
  --gray-20: #e5e5e5;
  --gray-40: #9f9f9f;
  --gray-60: #737373;
  --gray-80: #565656;
  --border: #c2c2c2;
  --border-2: #C4C4C4;
  --box-shadow: rgba(34, 60, 80, 0.2);

}

